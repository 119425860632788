import React from "react"
import { withAccountRewards } from "./withAccountRewards"
import { AccountHeader } from "../Header/AccountHeader"
import { StyledContainer } from "../../Styled/Container"
import { Page } from "./styledAccountRewards"
import LoyaltyIntroduction from "../../Loyalty/LoyaltyIntroduction/LoyaltyIntroduction"
import LoyaltyRewardTiers from "../../Loyalty/LoyaltyRewardTiers/LoyaltyRewardTiers"

const breadcrumb = {
  title: "My Rewards",
}

export const AccountRewards = withAccountRewards(
  ({ page, template, yotpo, currentRoute, customer }): JSX.Element => (
    console.log("page account rewards", page),
    (
      <>
        <AccountHeader location={location} page={breadcrumb} template={template} />
        <Page>
          <StyledContainer width={`xl`}>
            <LoyaltyIntroduction
              currentRoute={currentRoute}
              yotpo={yotpo}
              introGreeting={page.content[0].introGreeting}
              link={page.content[0].link}
              vipTierText={page.content[0].vipTierText}
              annualSpendIcon={page.content[0].annualSpendIcon}
              annualSpendText={page.content[0].annualSpendText}
              nextTierText={page.content[0].nextTierText}
              learnMore={page.content[0].learnMore}
              customer={customer}
            />
            <LoyaltyRewardTiers
              currentRoute={currentRoute}
              yotpo={yotpo}
              headerImage={page.content[1].headerImage}
              rewardsTitle={page.content[1].rewardsTitle}
              rewardsDescription={page.content[1].rewardsDescription}
              rewardsTiers={page.content[1].rewardsTiers}
              orchardButton={page.content[1].orchardButton}
              termsLink={page.content[1].termsLink}
            />
          </StyledContainer>
        </Page>
      </>
    )
  )
)
