import React, { memo, useMemo } from "react"
import { useCustomerContext } from "../../../hooks/useCustomer"
import { useTemplate } from "../../../hooks/useTemplate"
import { useLoyalty } from "../../../hooks/useLoyalty"
import { useCore } from "../../../hooks/useCore"

export const withAccountRewards = Component =>
  memo(({ name = "AccountRewards", page, template, yotpo }: any) => {
    const { customer } = useCustomerContext()
    const { global } = useTemplate()
    const {
      helpers: { isBrowser },
    } = useCore()

    const loyaltyUserData: any = useLoyalty()
    const currentRoute = isBrowser ? window.location.pathname : null

    const locales = useMemo(
      () => ({
        ...global,
        ...page,
        ...yotpo,
        currentRoute,
      }),
      [global, page, yotpo, currentRoute]
    )
    const title = customer?.firstName ? locales?.additionalHeading?.replace(`{name}`, customer?.firstName) : page?.title

    Component.displayName = name
    return useMemo(
      () => (
        <Component
          locales={locales}
          page={page}
          template={template}
          yotpo={loyaltyUserData}
          currentRoute={currentRoute}
          title={title}
          customer={customer}
        />
      ),
      [locales, page, template, currentRoute, title, customer, loyaltyUserData]
    )
  })
