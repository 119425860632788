import React from "react"
import { graphql } from "gatsby"

import { withAuthentication } from "../../hoc/withAuthentication"
import { AccountRewards as Page } from "../../components/Account/Rewards/AccountRewards"

export const query = graphql`
  query {
    page: sanityPageAccountRewards {
      content: _rawContent(resolveReferences: { maxDepth: 10 })
    }
    template: sanityTemplateAccount {
      bannerTitle
      bannerText
      bannerBackground
      bannerImage: _rawBannerImage(resolveReferences: { maxDepth: 2 })
      metadata: _rawMetadata(resolveReferences: { maxDepth: 4 })
    }
  }
`

const Component = withAuthentication(({ data, ...props }): JSX.Element => <Page {...props} {...data} />)

export default Component
